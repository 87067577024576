import { useEffect } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "state"
import { fetchDetailBrand, fetchListCategory, fetchListProvinceId, fetchPublicBrand, getStatusLoading } from "./actions"
import { fetchBrandById, fetchNewBrandById, fetchBrandCityData, fetchBrandData, fetchCategoryData, fetchNewBrandData } from "./fetchData"

export const UseCoreHome = () => {
    const dispatch = useAppDispatch()
    const dataHome = GetDataHome()
    const { cityId } = dataHome
    
    useEffect(() => {
        (async() => {
            dispatch(getStatusLoading({ isLoading: true }))
            const res = await fetchBrandData()
            const resNewBrand = await fetchNewBrandData()
            const resCategory = await fetchCategoryData()
            const arr = [...resNewBrand?.ListBrand, ...res?.ListBrand]
            dispatch(fetchPublicBrand({ListBrand: arr}))
            dispatch(fetchListCategory(resCategory))
            dispatch(getStatusLoading({ isLoading: false }))
        })()
    }, [])

    useEffect(() => {
        (async() => {
            dispatch(getStatusLoading({ isLoading: true }))
            const resCityIdBrand = await fetchBrandCityData(cityId)
            dispatch(fetchListProvinceId(resCityIdBrand))
            dispatch(getStatusLoading({ isLoading: false }))
        })()
    }, [cityId])
}

export const UseDetailBrand = (itemId:string|number, isNewBrand: string) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        const getData = async() => {
            if(isNewBrand) {
                const resBrandItem = await fetchNewBrandById(itemId)
                dispatch(fetchDetailBrand(resBrandItem))
            } else {
                const resBrandItem = await fetchBrandById(itemId)
                dispatch(fetchDetailBrand(resBrandItem))
            }
            
        }
        if (itemId){
            getData()
        }
    }, [dispatch, isNewBrand, itemId])
}


export const GetDataHome = () => {
    const data = useSelector<AppState, AppState['home']>((state) => state.home)
    return data
}