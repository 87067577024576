import axios from "axios";
import { API_URL } from "config/api/API_URL";
import { BrandApi } from "config/api/brand";
import { BrandItem, Brandtype } from "./type";

export const fetchBrandData = async (): Promise<{ListBrand:Brandtype[]|null}> => {
    try {
        const res = await BrandApi?.getBrand() 
        const brand = res?.data?.items
        return {
            ListBrand: brand?.filter((dt) => dt?.images)
        }
    } catch (error) {
        console.log(error)
        return {
            ListBrand: []
        }
    }
}

export const fetchNewBrandData = async (): Promise<{ListBrand:Brandtype[]|null}> => {
    try {
        const res = await BrandApi?.getNewBrand()
        const renderData = res?.data?.map((dt) => {
            return {
                banner: "",
                cat_id:"",
                cat_title: "",
                description:dt?.description,
                gift_count:"",
                id: dt?._id,
                images: dt?.logo,
                parent_cat_id:"1",
                title: dt?.brandName,
                isNewBrand: !false
            }
        })
        return {
            ListBrand: renderData
        }
    } catch (error) {
        console.log(error)
        return {
            ListBrand: []
        }
    }
}

export const fetchCategoryData = async (): Promise<{ListCategory:Brandtype[]|null}> => {
    try {
        const res = await BrandApi?.getCategory()
        return {
            ListCategory: res?.data
        }
    } catch (error) {
        console.log(error)
        return {
            ListCategory: []
        }
    }
}

export const fetchBrandCityData = async (cityIdState:string): Promise<{ListProvinceId:string[]|null}> => {
    if(cityIdState){
        try {
            const res = await axios.get(`${API_URL.BRANDCITY}/${cityIdState}`)
            const resNewBrand = await BrandApi?.getNewBrand()
            
            const newBrnadByAddress = resNewBrand?.data?.filter((dt) => dt?.address === cityIdState)
            const oldProvinceId = Array.isArray(res?.data?.data) ? res?.data?.data : res?.data?.data?.brandIds
            const newProvinceId = newBrnadByAddress?.map((dt) => {
                return dt?._id
            })
            return {
                ListProvinceId: [...oldProvinceId, ...newProvinceId]
            }
        } catch (error) {
            console.log(error)
            return {
                ListProvinceId: []
            }
        }
    } else {
        return {
            ListProvinceId: []
        }
    }
}

export const fetchBrandById = async (itemId:string|number): Promise<{brandItem:BrandItem[]}> => {
    try {
        const res = await BrandApi?.getDetailBrand({id:itemId})
        return {
            brandItem: res?.data
        }
    } catch (error) {
        console.log(error)
        return {
            brandItem: null
        }
    }
} 

export const fetchNewBrandById = async (itemId:string|number): Promise<{brandItem:BrandItem[]}> => {
    try {
        const res = await BrandApi?.getDetailNewBrand({id:itemId})
        const { data } = res
        const arr = data?.map((dt) => {
            return {
                id: dt?._id,
                brand: dt?.name,
                brand_id: dt?.provider,
                cat_id: "",
                cat_title: dt?.name,
                gift_id: "",
                title: dt?.name,
                type: "1",
                price: dt?.price?.toString(),
                point: "",
                view: "",
                quantity: dt?.amount,
                stock: "",
                image: "",
                images_rectangle: [],
                expire_duration: "",
                code_display: "",
                code_display_type: "",
                price_promo: dt?.promoPrice?.toString(),
                start_promo: "",
                end_promo: "",
                is_promo: "",
                is_unfix: "",
                brandLogoLoyalty: "",
                brandImage: "",
                brand_name: dt?.name,
                brand_online: "",
                parent_cat_id: "",
                usage_check: "",
                content: "",
                note: "",
                office: []
            }
        })
        return {
            brandItem: arr
        }
    } catch (error) {
        console.log(error)
        return {
            brandItem: null
        }
    }
}